import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { Show } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";
import { getDestinationUrlFromMapping, isUrlKeyOfMapping } from "~/utils/tools";

import "./PitchimmoBanner.css";

export default function PitchimmoBanner(props: { url: string }) {
  const settings = useDrupalSettingsContext();
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <Show
        when={
          settings.pitch.pitch_disp &&
          isUrlKeyOfMapping(props.url, settings.pitch.urls)
        }
      >
        <a
          href={getDestinationUrlFromMapping(props.url, settings.pitch.urls)}
          target="_blank"
          class="pitchimmo-link"
          innerHTML={settings.pitch.banner}
          onClick={() => {
            sendClick("banner-pitch", "list");
          }}
        />
      </Show>
    </>
  );
}
